import { default as _91_46_46_46cms_93ZL5McoW4OjMeta } from "/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93IxAPJnALcVMeta } from "/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/blog/[...slug].vue?macro=true";
import { default as indexiUqZQWBBEJMeta } from "/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93bxURCAlIy0Meta } from "/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/catalog/[...slug].vue?macro=true";
import { default as temp_catalog_need_32_remove183vcZevjcMeta } from "/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/catalog/temp_catalog_need _remove.vue?macro=true";
import { default as _91_46_46_46slug_93XL6VKr4xEnMeta } from "/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/product/[...slug].vue?macro=true";
import { default as indexvtnnlKlzmIMeta } from "/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/product/index.vue?macro=true";
import { default as _91_46_46_46slug_93ZXrlccsJTsMeta } from "/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/realty/[...slug].vue?macro=true";
import { default as index8dn1VgFV8ZMeta } from "/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/sitemap/index.vue?macro=true";
import { default as indexd2j7Skb1sHMeta } from "/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/wish/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93ZL5McoW4OjMeta?.name ?? "cms",
    path: _91_46_46_46cms_93ZL5McoW4OjMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93ZL5McoW4OjMeta || {},
    alias: _91_46_46_46cms_93ZL5McoW4OjMeta?.alias || [],
    redirect: _91_46_46_46cms_93ZL5McoW4OjMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93IxAPJnALcVMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93IxAPJnALcVMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93IxAPJnALcVMeta || {},
    alias: _91_46_46_46slug_93IxAPJnALcVMeta?.alias || [],
    redirect: _91_46_46_46slug_93IxAPJnALcVMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexiUqZQWBBEJMeta?.name ?? "blog",
    path: indexiUqZQWBBEJMeta?.path ?? "/blog",
    meta: indexiUqZQWBBEJMeta || {},
    alias: indexiUqZQWBBEJMeta?.alias || [],
    redirect: indexiUqZQWBBEJMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bxURCAlIy0Meta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93bxURCAlIy0Meta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93bxURCAlIy0Meta || {},
    alias: _91_46_46_46slug_93bxURCAlIy0Meta?.alias || [],
    redirect: _91_46_46_46slug_93bxURCAlIy0Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: temp_catalog_need_32_remove183vcZevjcMeta?.name ?? "catalog-temp_catalog_need _remove",
    path: temp_catalog_need_32_remove183vcZevjcMeta?.path ?? "/catalog/temp_catalog_need%20_remove",
    meta: temp_catalog_need_32_remove183vcZevjcMeta || {},
    alias: temp_catalog_need_32_remove183vcZevjcMeta?.alias || [],
    redirect: temp_catalog_need_32_remove183vcZevjcMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/catalog/temp_catalog_need _remove.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93XL6VKr4xEnMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93XL6VKr4xEnMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93XL6VKr4xEnMeta || {},
    alias: _91_46_46_46slug_93XL6VKr4xEnMeta?.alias || [],
    redirect: _91_46_46_46slug_93XL6VKr4xEnMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexvtnnlKlzmIMeta?.name ?? "product",
    path: indexvtnnlKlzmIMeta?.path ?? "/product",
    meta: indexvtnnlKlzmIMeta || {},
    alias: indexvtnnlKlzmIMeta?.alias || [],
    redirect: indexvtnnlKlzmIMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ZXrlccsJTsMeta?.name ?? "realty-slug",
    path: _91_46_46_46slug_93ZXrlccsJTsMeta?.path ?? "/realty/:slug(.*)*",
    meta: _91_46_46_46slug_93ZXrlccsJTsMeta || {},
    alias: _91_46_46_46slug_93ZXrlccsJTsMeta?.alias || [],
    redirect: _91_46_46_46slug_93ZXrlccsJTsMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/realty/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index8dn1VgFV8ZMeta?.name ?? "sitemap",
    path: index8dn1VgFV8ZMeta?.path ?? "/sitemap",
    meta: index8dn1VgFV8ZMeta || {},
    alias: index8dn1VgFV8ZMeta?.alias || [],
    redirect: index8dn1VgFV8ZMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: indexd2j7Skb1sHMeta?.name ?? "wish",
    path: indexd2j7Skb1sHMeta?.path ?? "/wish",
    meta: indexd2j7Skb1sHMeta || {},
    alias: indexd2j7Skb1sHMeta?.alias || [],
    redirect: indexd2j7Skb1sHMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20240823083539/pages/wish/index.vue").then(m => m.default || m)
  }
]